import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { v4 as uuid } from "uuid";
import ReactGA from 'react-ga4';

const LoanForm = (props) => {
	const sendEventToGoogleAnalytics = (values, action) => {		
		ReactGA.event({
		  category: "custom_buckle_event",
		  action: action,
		  label: JSON.stringify(values),
		});
	};
	return (
		<Formik
			initialValues={{
				name: props.loanToEdit ? props.loanToEdit.name : "",
				balance: props.loanToEdit ? props.loanToEdit.balance : "",
				interestRate: props.loanToEdit ? props.loanToEdit.interestRate : "",
				payment: props.loanToEdit ? props.loanToEdit.payment : "",
			}}
			validationSchema={Yup.object({
				name: Yup.string().required("Required"),
				balance: Yup.number("Must be a number")
					.typeError("Must be a number (please do not include ',' or other symbols)")
					.required("Required")
					.min(1, "You don't have a loan if there's no principal!")
					.test("is-currency", "Use up to two decimal places: 0.00", (value) =>
						/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{0,2})?$/.test(value)
					),
				interestRate: Yup.number("Must be a number")
					.typeError("Must be a number (do not include % sign)")
					.required("Required")
					.min(0, "Interest Rate must be more than 0"),
				payment: Yup.number("Must be a number")
					.typeError("Must be a number (please do not include ',' or other symbols)")
					.required("Required")
					.min(1, "Minimum payment must be more than 1")
					.test("is-currency", "Use up to two decimal places: 0.00", (value) =>
						/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{0,2})?$/.test(value)
					),
			})}
			onSubmit={(values, { setSubmitting }) => {
				setTimeout(() => {
					if (props.loanToEdit) {
						const newArr = props.loans.map((obj) => {
							if (obj.id === props.loanToEdit.id) {
								return {
									...obj,
									name: values.name,
									balance: parseFloat(values.balance),
									interestRate: parseFloat(values.interestRate),
									payment: parseFloat(values.payment),
								};
							}
							return obj;
						});
						props.setLoans(newArr);
						props.setLoanToEdit(false);
						sendEventToGoogleAnalytics(values, 'loan_edited');
						toast.success("Loan Saved");
					} else {
						props.setLoans((prev) => [
							...prev,
							{
								id: uuid(),
								name: values.name,
								balance: parseFloat(values.balance),
								interestRate: parseFloat(values.interestRate),
								payment: parseFloat(values.payment),
							},
						]);
						
						sendEventToGoogleAnalytics(values, 'loan_added');
						toast.success("Loan Added");
					}
				}, 400);
				props.handleShowModal();
			}}
		>
			<Form className="pt-1 pr-1 sm:pr-5 mb-4" id="loanForm">
				<div className="relative">
					<label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2 mt-7">
						Loan Name
					</label>
					<Field
						name="name"
						type="text"
						className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						onFocus={(e) => e.target.select()}
					/>
					<p className="text-red-500 text-xs italic absolute -bottom-4">
						<ErrorMessage name="name" />
					</p>
				</div>
				<div className="relative">
					<label htmlFor="balance" className="block text-gray-700 text-sm font-bold mb-2 mt-7">
						Principal Remaining
					</label>
					<Field
						name="balance"
						type="text"
						className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						inputMode="decimal"
						onFocus={(e) => e.target.select()}
					/>
					<p className="text-red-500 text-xs italic absolute -bottom-4">
						<ErrorMessage name="balance" />
					</p>
				</div>
				<div className="relative">
					<label htmlFor="interestRate" className="block text-gray-700 text-sm font-bold mb-2 mt-7">
						Interest (%)
					</label>
					<Field
						name="interestRate"
						type="text"
						className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						inputMode="decimal"
						onFocus={(e) => e.target.select()}
					/>
					<p className="text-red-500 text-xs italic absolute -bottom-4">
						<ErrorMessage name="interestRate" />
					</p>
				</div>
				<div className="relative">
					<label htmlFor="payment" className="block text-gray-700 text-sm font-bold mb-2 mt-7">
						Monthly Minimum Payment ($)
					</label>
					<Field
						name="payment"
						type="text"
						className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						inputMode="decimal"
						onFocus={(e) => e.target.select()}
					/>
					<p className="text-red-500 text-xs italic absolute -bottom-4">
						<ErrorMessage name="payment" />
					</p>
				</div>
			</Form>
		</Formik>
	);
};
export default LoanForm;
