import { Link } from 'react-router-dom';

export default function Footer() {
	const d = new Date();
	let year = d.getFullYear();
	
	return (
		<footer className = "mt-auto border-t border-gray-200 max-w-7xl mx-auto w-full">
			<div className = "py-5 text-center text-sm text-gray-500">
				<p>&copy; {year} <a className = "hover:underline" href = "https://buckleupstudios.com" target = "_blank" rel="noreferrer">Buckle Up Studios</a></p>
				<Link to = "/privacy-policy" className = "block mt-4 hover:underline">Privacy Policy</Link>
			</div>
		</footer>
	);
}
