import PaymentStrategyCard from "./PaymentStrategyCard";
import MyLoansCard from "./MyLoansCard";

const Aside = ({
	loans,
	setLoans,
	paymentStrategy,
	handlePaymentStrategyChange,
	monthlyPayment,
	setMonthlyPayment,
	totalMinimumPayment,
	handleShowModal,
	setShowModal,
	showModal
}) => {
	return (
		<aside className="col-span-12 order-2 lg:col-span-4 lg:order-1">
			<PaymentStrategyCard
				paymentStrategy={paymentStrategy}
				setLoans={setLoans} 
				handlePaymentStrategyChange={handlePaymentStrategyChange}
				monthlyPayment={monthlyPayment}
				setMonthlyPayment={setMonthlyPayment}
				totalMinimumPayment={totalMinimumPayment}
				loans={loans}
			/>
			<MyLoansCard 
				loans={loans} 
				setLoans={setLoans} 
				handleShowModal={handleShowModal}
				setShowModal={setShowModal}
				showModal={showModal}
			/>
		</aside>
	);
};

export default Aside;
