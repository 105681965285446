import { useState, useEffect } from "react";
import Main from "../components/Main/Main";
import HomepageBodyContent from "../components/Content/HomepageBodyContent";
import Aside from "../components/Aside/Aside";
import { calculateTotalMinimumPayment } from "../components/Main/helperFunctions.js";
import snowFlakeImage from '../images/snowflake.jpg';
import ReactGA from 'react-ga4';

const LoanCalculator = ({title}) => {
	const [loans, setLoans] = useState(JSON.parse(localStorage.getItem('loans')) || []);
	
	useEffect(() => {
	  localStorage.setItem('loans', JSON.stringify(loans));
	}, [loans]);
	
	useEffect(() => {
		document.title = title
	});
	
	
	const totalMinimumPayment = calculateTotalMinimumPayment(loans);
	
	const [paymentStrategy, setpaymentStrategy] = useState("avalanche");
	const handlePaymentStrategyChange = (type) => {
		setpaymentStrategy(type);
		ReactGA.event({
		  category: "custom_buckle_event",
		  action: "change_payment_strategy",
		  label: type,
		});
	};
	
	const [monthlyPayment, setMonthlyPayment] = useState(totalMinimumPayment);
	useEffect(() => {
	  setMonthlyPayment(totalMinimumPayment);
	}, [loans, totalMinimumPayment]);
	
	const [showModal, setShowModal] = useState(false);
	const handleShowModal = (name) => {
		if(name){
			ReactGA.event({
		  	category: "custom_buckle_event",
		  	action: "modal_opened",
		  	label: name,
			});
		}
		setShowModal((prev) => (prev === false ? name : false));
	};

	return(
		<>
			<img src = {snowFlakeImage} className = "hidden absolute -z-50 -left-[300px] -bottom-[200px] opacity-20 w-[700px] lg:block" alt = "snowflake background image" aria-hidden='true' />
			
			<div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto py-3 lg:py-6 px-4 lg:px-6 w-full">
				{loans.length > 0 ?
				<Aside
					loans={loans}
					setLoans={setLoans}
					paymentStrategy={paymentStrategy}
					handlePaymentStrategyChange={handlePaymentStrategyChange}
					monthlyPayment={monthlyPayment}
					setMonthlyPayment={setMonthlyPayment}
					totalMinimumPayment={totalMinimumPayment}
					handleShowModal={handleShowModal}
					setShowModal={setShowModal}
					showModal={showModal}
				/> : ''}
				<Main
					loans={loans}
					setLoans={setLoans}
					monthlyPayment={monthlyPayment}
					paymentStrategy={paymentStrategy}
					totalMinimumPayment={totalMinimumPayment}
					handleShowModal={handleShowModal}
					setShowModal={setShowModal}
					showModal={showModal}
				/>
			</div>
			
			<HomepageBodyContent />
		</>
	)
	
}
export default LoanCalculator;