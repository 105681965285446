import { TrashIcon, PencilIcon } from "@heroicons/react/outline";

const Loan = ({ item, editLoan, deleteLoan, animateOut }) => {
	return (
		<div
			className={`py-3 px-4 text-base flex items-center border-b overflow-hidden transition-max-height max-h-24 duration-500 ${
				animateOut && "max-h-0 py-0"
			}`}
		>
			<div>
				<p className="font-bold">{item.name}</p>
				<p className="text-sm">
					${item.balance} at {item.interestRate}%
				</p>
			</div>
			<div
				className="h-9 w-9 ml-auto shrink-0 flex items-center justify-center rounded-full cursor-pointer hover:bg-gray-100 active:bg-gray-200"
				onClick={() => editLoan(item)}
			>
				<PencilIcon className="h-5 w-5 text-gray-500" />
			</div>
			<div
				className="h-9 w-9 ml-1 flex shrink-0 items-center justify-center rounded-full cursor-pointer hover:bg-gray-100 active:bg-gray-200"
				onClick={() => deleteLoan(item)}
			>
				<TrashIcon className="h-5 w-5 text-gray-500" />
			</div>
		</div>
	);
};
export default Loan;
