import {useEffect} from 'react';
import PrivacyPolicyContent from '../components/Content/PrivacyPolicyContent';

const PrivacyPolicy = ({title}) => {
	useEffect(() => {
		document.title = title
	});

	return (
		<div className="max-w-4xl mx-auto pt-6 pb-24 px-4 lg:px-6 w-full">
			<PrivacyPolicyContent />
		</div>
	);
}

export default PrivacyPolicy;