import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import RootLayout from './pages/Root';
import LoanCalculator from "./pages/LoanCalculator";
import ContactPage from "./pages/Contact";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import Error404 from "./pages/404";
const router = createBrowserRouter([
	{ 
		path: '/', 
		element: <RootLayout />,
		errorElement: <Error404 title = "Error: Page Not Found | LoanChill"/>,
		children: [
			{ path: '/', element: <LoanCalculator title = "Loan Payoff Calculator | Loan Chill"/>},
			{ path: '/contact', element: <ContactPage title = "Contact | Loan Chill"/>},
			{ path: '/privacy-policy', element: <PrivacyPolicyPage title = "Privacy Policy | Loan Chill"/>}
		]
	},
]);

const App = () => {
	return (
		<RouterProvider router={router}/>
	);
};

export default App;
