export const toCurrency = (num) => {
	if (num > 0 && !Number.isInteger(num)) {
		return (Math.round(num * 100) / 100).toFixed(2);
	} else {
		return num;
	}
};

export const truncateLoanName = (name, amount) => {
	return name.length >= amount ? name.substring(0, amount).concat("...") : name;
};

export const calculateTotalMinimumPayment = (loans) => {
	let totalMinimumPayment = 0;
	loans.forEach((obj) => (totalMinimumPayment += obj.payment));
	return toCurrency(totalMinimumPayment);
};

export const calcTotalBalance = (loans) => {
	let totalBalance = 0;
	loans.forEach((loan) => (totalBalance += loan.balance));
	return toCurrency(totalBalance);
};

export const calculateAverageInterestRate = (list) => {
	let averageInterestRate = list
		.map((obj) => {
			return obj.balance * (obj.interestRate / 100);
		})
		.reduce((a, b) => a + b);
	return ((averageInterestRate / calcTotalBalance(list)) * 100).toFixed(2);
};
