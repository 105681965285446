import {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Navbar from "../components/PageSections/Navbar";

const Error404 = ({title}) => {
	
	useEffect(() => {
		document.title = title
	});
	
	return (
		<>
			<Navbar />
			<div className="max-w-4xl mx-auto py-3 py-6 px-4 lg:px-6 text-center">
				<h1 className = "text-3xl md:text-5xl font-medium text-cyan-500">Error: Page Not Found</h1>
				<p className = "text-lg mt-4">We're sorry, the page you're looking for can't be found. Maybe try starting at the <Link className = "text-cyan-700 underline hover:no-underline" to = "/">homepage</Link>?</p>
			</div>
		</>
	);
}

export default Error404;