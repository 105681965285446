import ReactGA from 'react-ga4';

const PrincipleInterestSwitchButton = ({ dataToShow, setDataToShow }) => {
	const handleSwitchButton = (name) =>{
		setDataToShow(name);
		ReactGA.event({
		  category: "custom_buckle_event",
		  action: "switch_data_to_show",
		  label: name,
		});
	}
	return (
		<div className="join flex justify-center py-5 no-animation">
			<button
				className={`btn btn-sm join-item min-h-2 h-2 ${dataToShow === "principal" && "btn-primary"}`}
				onClick={() => handleSwitchButton("principal")}
			>
				Show Principal
			</button>
			<button
				className={`btn btn-sm join-item ${dataToShow === "interest" && "btn-primary"}`}
				onClick={() => handleSwitchButton("interest")}
			>
				Show Interest Paid
			</button>
		</div>
	);
};

export default PrincipleInterestSwitchButton;
