import { Link, NavLink } from 'react-router-dom';
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import logo from '../../images/loan-chill.jpg';
import {
	AtSymbolIcon,
	MenuIcon,
	XIcon,
	ChartBarIcon
} from "@heroicons/react/outline";

const mobileMenu = [
	{
		name: "Home",
		href: "/",
		icon: ChartBarIcon,
	},
	{
		name: "Contact",
		href: "/contact",
		icon: AtSymbolIcon,
	},
];

export default function Navbar() {
	return (
		<Popover className="relative bg-white">
			{/* Start Desktop Nav */}
			<div className="max-w-7xl mx-auto px-4 sm:px-6">
				<div className="flex justify-between items-center border-b-2 border-gray-100 py-2 md:justify-start md:space-x-10">
					<div className="flex justify-start lg:w-0 lg:flex-1">
						<Link to = "/">
							<span className="sr-only">LoanChill</span>
							<img
								className="h-14 w-auto sm:h-16"
								src={logo}
								alt="LoanChill Logo"
							/>
						</Link>
					</div>
					
					<div className="-mr-2 -my-2 md:hidden">
						<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
							<span className="sr-only">Open menu</span>
							<MenuIcon className="h-6 w-6" aria-hidden="true" />
						</Popover.Button>
					</div>
					
					<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 gap-x-6">
						<NavLink 
							to="/" 
							className={({ isActive }) => [
									"whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900",
									isActive ? "underline" : undefined
								].join(" ")
							}
						>
							Home
						</NavLink>
						<NavLink 
							to="/contact" 
							className={({ isActive }) => [
									"whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900",
									isActive ? "underline" : undefined
								].join(" ")
							}
						>
							Contact
						</NavLink>
					</div>
				</div>
			</div>
			{/* END Desktop Nav */}

			<Transition
				as={Fragment}
				enter="duration-200 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Popover.Panel
					focus
					className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
				>
					<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
						<div className="pt-5 pb-6 px-5">
							<div className="flex items-center justify-between">
								<div>
									<img
										className="h-12 w-auto"
										src={logo}
										alt="LoanChill Logo"
									/>
								</div>
								<div className="-mr-2">
									<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
										<span className="sr-only">Close menu</span>
										<XIcon className="h-6 w-6" aria-hidden="true" />
									</Popover.Button>
								</div>
							</div>
							<div className="mt-6">
								<nav className="grid gap-y-8">
									{mobileMenu.map((item) => (
										<Popover.Button as={NavLink}
											key={item.name}
											to={item.href}
											className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
										>
											<item.icon
												className="flex-shrink-0 h-6 w-6 text-cyan-600"
												aria-hidden="true"
											/>
											<span className="ml-3 text-base font-medium text-gray-900">
												{item.name}
											</span>											
										</Popover.Button>
										
									))}
								</nav>
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	);
}
