import { useState } from "react";
import toast from "react-hot-toast";
import { calcTotalBalance, toCurrency } from "../Main/helperFunctions.js";
import ReactGA from 'react-ga4';

const PaymentStrategyCard = ({
	paymentStrategy,
	handlePaymentStrategyChange,
	monthlyPayment,
	setMonthlyPayment,
	totalMinimumPayment,
	loans,
}) => {
	const [minimumPaymentRangeIsLowClass, setMinimumPaymentRangeIsLowClass] = useState(false);
	const [monthlyPaymentMouseUp, setMonthlyPaymentMouseUp] = useState(false);
	const totalBalance = calcTotalBalance(loans);
	const handleMonthlyPaymentChange = (e) => {
		ReactGA.event({
		  category: "custom_buckle_event",
		  action: "change_payment_slider",
		  label: e.target.value,
		});
		if (parseInt(e.target.value) <= parseInt(totalMinimumPayment)) {
			if (monthlyPaymentMouseUp) {
				setMinimumPaymentRangeIsLowClass(false);
				return;
			}
			setMonthlyPayment(totalMinimumPayment);
			setMinimumPaymentRangeIsLowClass(true);
		}
		if (parseInt(e.target.value) > parseInt(totalMinimumPayment)) {
			setMonthlyPayment(parseInt(e.target.value));
			setMinimumPaymentRangeIsLowClass(false);
		}
	};

	const maxSliderValue = () => {
		let maxSlider = totalBalance / 3;
		maxSlider = Math.ceil(maxSlider / 10) * 10;
		return maxSlider > totalMinimumPayment ? toCurrency(maxSlider) : totalBalance;
	};

	const minSliderValue = () => {
		if (totalMinimumPayment < 200) {
			return 0;
		} else {
			let minSlider = totalMinimumPayment - 200;
			return Math.ceil(minSlider / 10) * 10;
		}
	};
	
	const onSliderMouseDown = () => {
		if(loans.length === 0){
			toast.error("Add a loan first!", {
				duration: 2000,
			});
			return;
		}
		setMonthlyPaymentMouseUp(false)
	}
	
	const onCheckboxMouseDown = () => {
		if(loans.length === 0){
			toast.error("Add a loan first!", {
				duration: 2000,
			});
			return;
		}
	}

	return (
		<div className="bg-white rounded-sm border-2 border-cyan-600 flex flex-col lg:border-0 lg:drop-shadow-md">
			<h3 className="hidden border-b border-gray-300 p-3 font-bold text-gray-700 text-xl lg:block">
				Payment Strategy
			</h3>
			<fieldset className="block p-3 form-control order-3 lg:mt-4 lg:order-2">
				<legend className="font-bold appearance-none">Payment Strategy Type</legend>
				{["avalanche", "snowball"].map((type) => (
					<label className="block items-center mb-2" key={type} id={type} onMouseDown={() => onCheckboxMouseDown()}>
						<input
							type="checkbox"
							className="checkbox checkbox-primary checkbox-sm focus:ring-0 focus:ring-offset-0"
							id={type}
							name={type}
							value={type}
							checked={paymentStrategy === type && true}
							aria-checked={paymentStrategy === type && true}
							onChange={() => handlePaymentStrategyChange(type)}
							disabled={loans.length === 0 ? true : false}
						/>
						<span className="ml-2 cursor-pointer">
							{type === "avalanche"
								? "Highest Interest Rate (Avalanche)"
								: "Lowest Principal (Snowball)"}
						</span>
					</label>
				))}
			</fieldset>
			<div className="p-3 pt-3 order-2 lg:pt-5 lg:order-3">
				<h4 id="monthlyPaymentHeading" className="font-bold">
					Monthly Payment:
					<span className="text-secondary"> ${monthlyPayment}</span>
				</h4>
				<input
					className={`range mt-4 mb-2 lg:mb-4 ${
						minimumPaymentRangeIsLowClass ? "range-error" : "range-primary"
					}`}
					type="range"
					min={minSliderValue()}
					max={maxSliderValue()}
					step={totalMinimumPayment > 1000 ? "10" : "5"}
					value={monthlyPayment}
					aria-valuemin={totalMinimumPayment * 0.8}
					aria-valuemax={totalMinimumPayment + totalMinimumPayment * 3}
					aria-valuenow={monthlyPayment}
					aria-labelledby="monthlyPaymentHeading"
					onChange={(e) => handleMonthlyPaymentChange(e)}
					onMouseUp={() => setMonthlyPaymentMouseUp(true)}
					onMouseDown={() =>onSliderMouseDown()}
				/>
			</div>
		</div>
	);
};
export default PaymentStrategyCard;
