import { addMonths } from "date-fns";
import { toCurrency, calculateTotalMinimumPayment } from "./helperFunctions.js";

const getTotalBal = (list) => {
	for (var i = 0; i < list.length; i++) {
		list.totalBal += list[i].balance;
	}
	list.originalAmountOwed = list.totalBal;
};

const addLoansListProperties = (list) => {
	list.totalPaid = 0;
	list.totalBal = 0;
	list.months = 0;
	list.extraMoneyToAdd = 0;
};

const addLoanProperties = (list) => {
	for (let i = 0; i < list.length; i++) {
		list[i].totalInterestPaid = 0;
		list[i].totalPaid = 0;
		list[i].months = 0;
		list[i].paymentsArray = [];
		list[i].interestArray = [];
		list[i].originalAmountOwed = list[i].balance;
	}
};

const compareBal = (a, b) => {
	return a.balance - b.balance || b.interestRate - a.interestRate;
};

const compareInterest = (a, b) => {
	return b.interestRate - a.interestRate || a.balance - b.balance;
};

const allocateExtraMoney = (list, extraPayment) => {
	if (list.payMinimum) {
		return;
	}
	for (let i = 0; i < list.length; i++) {
		let debt = list[i];

		if (debt.balance > 0.1) {
			debt.payment = debt.payment + extraPayment;
			break;
		}
	}
};

const simulatePayments = (list) => {
	let months = 0;
	addLoanProperties(list);

	while (list.totalBal - 0.5 > 0 && months < 840) {
		if (list.extraMoneyToAdd) {
			allocateExtraMoney(list, list.extraMoneyToAdd);
			list.extraMoneyToAdd = 0;
		}
		for (let i = 0; i < list.length; i++) {
			let loan = list[i];
			if (loan.balance > 0) {
				addMonthlyInterest(list, loan);
			}
		}
		for (let i = 0; i < list.length; i++) {
			let loan = list[i];
			if (loan.balance > 0) {
				makeMonthlyPayment(list, loan);
			}
		}
		months++;
		addFinalPaymentsToArray(list);
	}
};
const addFinalPaymentsToArray = (list) => {
	for (let i = 0; i < list.length; i++) {
		list[i].paymentsArray.push(list[i].balance);
		list[i].interestArray.push(list[i].totalInterestPaid);
	}
};
const addMonthlyInterest = (list, debt) => {
	let monthlyInterest;
	monthlyInterest = calculateMonthlyInterest(debt);
	debt.balance += monthlyInterest;
	list.totalBal += monthlyInterest;
	debt.totalInterestPaid += monthlyInterest;
};
const calculateMonthlyInterest = (debt) => {
	return debt.balance * (debt.interestRate / 100 / 12);
};
const makeMonthlyPayment = (list, debt) => {
	let payment, isFinalPayment, surplus;

	payment = debt.payment;
	if (debt.balance - payment < 0.1) {
		isFinalPayment = true;
		payment = debt.balance;
		surplus = debt.payment - payment;
	}
	debt.balance = debt.balance - payment;
	list.totalBal = list.totalBal - payment;
	recordPayment(list, debt, payment);
	if (isFinalPayment) {
		list.extraMoneyToAdd += debt.payment;
		allocateSurplusPayment(list, surplus);
		isFinalPayment = false;
	}
};

const allocateSurplusPayment = (list, surplus) => {
	let remainingSurplus, debt;
	if (list.payMinimum) {
		return;
	}
	if (list.totalBal > 0) {
		for (let i = 0; i < list.length; i++) {
			debt = list[i];
			if (debt.balance > 0.1) {
				if (debt.balance - surplus > 0) {
					debt.balance -= surplus;
					list.totalBal -= surplus;
					list.totalPaid += surplus;
					debt.totalPaid += surplus;
					break;
				} else {
					remainingSurplus = surplus - debt.balance;
					list.totalBal -= debt.balance;
					list.totalPaid += debt.balance;
					debt.totalPaid += debt.balance;
					debt.balance = 0;
					list.extraMoneyToAdd += debt.payment;
					allocateSurplusPayment(list, remainingSurplus);
					break;
				}
			}
		}
	}
};

const recordPayment = (list, debt, payment) => {
	debt.totalPaid += payment;
	debt.months += 1;
	list.months = debt.months;
	list.totalPaid += payment;
};

const addDatesToChartArray = (array, monthsToAdd) => {
	const date = new Date();
	array = array.map((item, i) => ({
		name: addMonths(date, i + monthsToAdd),
		...item,
	}));
	return array;
};

const createChartArray = (list, dataToShow) => {
	const dataArray = [];
	const initialData = {};

	if (dataToShow === "paymentsArray") {
		for (let i = 0; i < list.length; i++) {
			initialData[list[i].name] = toCurrency(list[i].originalAmountOwed);
		}
		dataArray.push(initialData);
	}
	for (let i = 0; i < list.months; i++) {
		let monthData = {};
		for (let l = 0; l < list.length; l++) {
			monthData[list[l].name] = toCurrency(list[l][dataToShow][i]) || 0;
		}
		dataArray.push(monthData);
	}

	return addDatesToChartArray(dataArray, dataToShow === "paymentsArray" ? 0 : 1);
};

const formatForReturn = (list) => {
	const formattedObject = {};

	formattedObject.totalBal = toCurrency(list.originalAmountOwed);
	formattedObject.totalInterestPaid = toCurrency(list.totalPaid - list.originalAmountOwed);
	list.sort((a, b) => a.originalAmountOwed - b.originalAmountOwed);
	formattedObject.loanChart = createChartArray(list, "paymentsArray");
	formattedObject.interestChart = createChartArray(list, "interestArray");
	return formattedObject;
};

export const runSimulation = (originalLoans, paymentStrategy, monthlyPayment) => {
	let loansList = JSON.parse(JSON.stringify(originalLoans));
	loansList.payMinimum = false;

	let extraPayment = monthlyPayment - calculateTotalMinimumPayment(loansList);

	addLoansListProperties(loansList);
	getTotalBal(loansList);

	switch (paymentStrategy) {
		case "avalanche":
			loansList.sort(compareInterest);
			break;
		case "snowball":
			loansList.sort(compareBal);
			break;
		default:
			loansList.payMinimum = true;
			extraPayment = 0;
	}

	allocateExtraMoney(loansList, extraPayment);
	simulatePayments(loansList);
	return formatForReturn(loansList);
};
