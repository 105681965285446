import { useState } from "react";
import Loan from "./Loan";
import Modal from "../UI/Modal";
import LoanForm from "../UI/LoanForm";
import toast from "react-hot-toast";
import ReactGA from 'react-ga4';
import { ViewGridAddIcon, PencilAltIcon } from "@heroicons/react/outline";
import { calcTotalBalance, calculateAverageInterestRate } from "../Main/helperFunctions";

const MyLoansCard = ({ loans, setLoans, handleShowModal, setShowModal, showModal }) => {

	const [loanToEdit, setLoanToEdit] = useState(false);
	const editLoan = (item) => {
		setLoanToEdit(item);
		handleShowModal("editLoan");
	};

	const deleteLoan = (item) => {
		setLoans(
			loans.map((obj) => {
				if (obj.id === item.id) {
					return {
						...obj,
						animateOut: true,
					};
				}
				return obj;
			})
		);

		setTimeout(() => {
			setLoans(loans.filter((l) => l.id !== item.id));
		}, 500);
		
		ReactGA.event({
		  category: "custom_buckle_event",
		  action: "loan_deleted",
		  label: item['name'],
		});
		
		toast.success("Loan Deleted", {
			duration: 2000,
		});
	};
	return (
		<>
			<div className="bg-white rounded-sm border mt-5 lg:drop-shadow-md lg:border-0">
				<h3 className="border-b border-gray-300 p-3 font-bold text-gray-700 text-xl flex items-center">
					{loans.length > 0 ? 'My Loans' : 'Add a loan'}
					<button
						className="btn btn-sm btn-primary modal-button ml-auto"
						onClick={() => handleShowModal("addLoan")}
					>
						Add loan +
					</button>
				</h3>
				{loans.map((item) => {
					return (
						<Loan
							key={item.id}
							item={item}
							editLoan={editLoan}
							deleteLoan={deleteLoan}
							animateOut={item.animateOut}
						/>
					);
				})}
				{loans.length > 1 && (
					<div className="bg-primary/20 px-4 pt-2 pb-1 flex justify-around text-center text-slate-700 leading-4 text-sm">
						<div>
							Total Balance
							<span className="block font-bold text-base">${calcTotalBalance(loans)}</span>
						</div>
						<div>
							Average Interest Rate{" "}
							<span className="block font-bold text-base">
								{calculateAverageInterestRate(loans)}%
							</span>
						</div>
					</div>
				)}
			</div>
			<Modal
				showModal={showModal === "addLoan"}
				setShowModal={setShowModal}
				headline={"Add A Loan"}
				submitTitle={"Add Loan"}
				leftIcon={<ViewGridAddIcon className="h-6 w-6 text-white" aria-hidden="true" />}
			>
				<LoanForm setLoans={setLoans} handleShowModal={handleShowModal} />
			</Modal>
			<Modal
				showModal={showModal === "editLoan"}
				setShowModal={setShowModal}
				headline={`Edit: ${loanToEdit.name}`}
				submitTitle={"Save"}
				leftIcon={<PencilAltIcon className="h-6 w-6 text-white" aria-hidden="true" />}
			>
				<LoanForm
					setLoans={setLoans}
					loans={loans}
					setLoanToEdit={setLoanToEdit}
					handleShowModal={handleShowModal}
					loanToEdit={loanToEdit}
				/>
			</Modal>
		</>
	);
};
export default MyLoansCard;
