import { CalendarIcon, ClockIcon, CashIcon } from "@heroicons/react/outline";
import { format, addMonths } from "date-fns";
import StatCard from "./StatCard";
const StatBar = ({ totalInterestPaid, loanChartLength, numberOfLoans }) => {
	return (
		<>
			{loanChartLength >= 840 ? (
				<div className="py-4 px-5 border border-red-500 leading-6 rounded-lg mb-5 bg-red-300/10">
					<h3 className="text-center text-red-500 font-bold text-xl mb-2">
						Oh No! This payment plan seems impractical.
					</h3>
					<p className="text-center mb-3">
						Your payment plan was simulated for the next <strong>70 years</strong> and your loans
						could not be paid off.
					</p>
					<p className="text-center">
						While not always an option, contributing a lump sum whenever you can, or even just a bit
						more every month can make loans more manageable.
					</p>
				</div>
			) : (
				<div className="grid grid-cols-12 mb-5">
					<StatCard
						title={"Payoff Date"}
						mobileTitle = {"Payoff Date"}
						stat={
							numberOfLoans > 0 ?
							format(addMonths(new Date(), loanChartLength - 1), "MMM yyyy") : 
							'--'
						}
						icon={CalendarIcon}
						borderStyles={"rounded-tl-lg rounded-bl-lg border"}
					/>
					<StatCard
						title={"Total Interest Paid"}
						mobileTitle = {"Interest Paid"}
						stat={
							numberOfLoans > 0 ?
							`$${totalInterestPaid}` :
							'--'
						}
						icon={CashIcon}
						borderStyles={"border-t border-b"}
					/>
					<StatCard
						title={"Time Until Payoff"}
						mobileTitle = {"Time"}
						stat={
							numberOfLoans > 0 ?
							`${loanChartLength - 1} Months` :
							'--'
						}
						icon={ClockIcon}
						borderStyles={"border rounded-tr-lg rounded-br-lg"}
					/>
				</div>
			)}
		</>
	);
};

export default StatBar;
