const PrivacyPolicyContent = () => {
	return (
		<div className="prose lg:prose-lg max-w-4xl">
			<h2 className = "text-center">Privacy Policy for LoanChill</h2>
			
			<p>This Privacy Policy explains how we collect, use, and protect the information you provide while using LoanChill. We are committed to ensuring the privacy and security of your personal information. By accessing and using our website calculator, you consent to the practices described in this policy.</p>
			
			<h3>Purpose:</h3>
			<p>The purpose of our calculator is to assist you in discovering an optimized payment plan to minimize interest and become debt-free as quickly as possible.</p>
			
			<h3>Collection of Information:</h3>
			<p>We do not collect any personal information from our users. However, we do collect loan information, including the total amount, interest rate, and minimum payment, which is provided by you voluntarily.</p>
		
			<h3>Method of Collection:</h3>
			<p>The loan information is collected through user input on our website calculator.</p>
		
			<h3>Use of Information:</h3>
			<p>The loan information you provide is used solely for the purpose of computing an optimized payment plan based on the data provided. We do not use this information for any other purpose.</p>
		
			<h3>Third-Party Disclosure:</h3>
			<p>We do not share the loan information collected with any third parties. Your data remains strictly confidential and is used only within the context of providing the intended service.</p>
		
			<h3>Use of Cookies:</h3>
			<p>We use cookies on our website to enhance user experience and improve the functionality of our calculator. Cookies are small text files stored on your device that allow us to remember your preferences and provide a customized experience.</p>
		
			<h3>Data Security:</h3>
			<p>We prioritize the security of your information. We do not store any information on our servers. All loan information is saved locally in your browser.</p>
		
			<h3>User Consent:</h3>
			<p>By using our website calculator, you implicitly consent to the collection and use of the loan information you provide for the stated purpose. If you do not agree with this policy, please refrain from using our services.</p>
		
			<h3>Age Restrictions:</h3>
			<p>There are no age restrictions for using our website calculator. However, we recommend that individuals under the age of 18 seek parental consent or guidance when using our services.</p>
		
			<h3>GDPR Compliance:</h3>
			<p>We comply with the requirements of the General Data Protection Regulation (GDPR) regarding the collection, use, and protection of personal information. If you have any concerns or requests regarding your personal data, please contact us at keith@buckleupstudios.com.</p>
		</div>
	);
};

export default PrivacyPolicyContent;