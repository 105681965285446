import {useEffect} from 'react';

const Contact = ({title}) => {
	useEffect(() => {
		document.title = title
	});

	return (
		<div className="max-w-4xl mx-auto py-3 py-6 px-4 lg:px-6 text-center w-full">
			<h1 className = "text-3xl md:text-5xl font-medium text-cyan-500">Contact</h1>
			<p className = "text-lg mt-4">Want to suggest a feature? Have a bug to report? Or just want to say 'hi'?</p>
			<p className = "text-lg mt-4">Email: <a className = "font-bold hover:underline" href = "mailto:keith@buckleupstudios.com">keith@buckleupstudios.com</a></p>
		</div>
	);
}

export default Contact;