import { useRef } from 'react';
import { format } from "date-fns";
import { toCurrency, truncateLoanName } from "./helperFunctions.js";
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
} from "recharts";

const Graph = ({ data }) => {
	
	const arrayWithLoanNames = Object.keys(data[0]).filter(item => item !== 'name');
	
	const windowSize = useRef([window.innerWidth, window.innerHeight]);
	
	const calculateTotalForTooltip = (payload) => {
		return toCurrency(
			payload
				.map((obj) => {
					return parseFloat(obj.value);
				})
				.reduce((a, b) => a + b)
		);
	};
	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className="bg-white border px-3 py-2 rounded drop-shadow-md">
					<p className="text-center font-bold ">{format(label, "MMMM yyyy")}</p>
					{payload
						.slice(0)
						.reverse()
						.map((loan) => {
							return (
								<div key={loan.name} className="flex items-center text-sm mt-1">
									<div
										className="box-border w-4 h-4 mr-1"
										style={{
											backgroundColor: loan.fill,
											border: `1px solid ${loan.stroke}`,
											opacity: loan.fillOpacity,
										}}
									></div>
									<span className="mr-10">{truncateLoanName(loan.name, 20)}</span>
									<span className="ml-auto">${loan.value}</span>
								</div>
							);
						})}
					<p className="text-center mt-2 text-sm font-bold">
						Total ${calculateTotalForTooltip(payload)}
					</p>
				</div>
			);
		}

		return null;
	};

	return (
		<ResponsiveContainer width="100%" height={windowSize.current[0] >= 767 ? 400 : 200}>
			<AreaChart data={data}>
				<XAxis
					dataKey="name"
					tickFormatter={(date) => format(date, "MMM yyyy")}
					interval="preserveStartEnd"
					tickMargin={10}
				/>
				<YAxis tickFormatter={(number) => "$" + number} />
				<Tooltip content={<CustomTooltip />} />

				{arrayWithLoanNames.map((loanName, i) => {
					return (
						<Area
							key={loanName}
							type="monotone"
							dataKey={loanName}
							stroke={"#2f657a"}
							fill={"#77b9d2"}
							stackId="1"
							fillOpacity={(arrayWithLoanNames.length - i) / arrayWithLoanNames.length}
							strokeOpacity={(arrayWithLoanNames.length - i) / arrayWithLoanNames.length}
							strokeWidth={1}
							isAnimationActive={false}
						/>
					);
				})}
			</AreaChart>
		</ResponsiveContainer>
	);
};

export default Graph;
