const StatCard = ({ title, mobileTitle, stat, icon, borderStyles }) => {
	const Icon = icon;
	return (
		<div className={`flex flex-col-reverse py-1 px-3 items-center justify-center col-span-4 md:py-3 md:px-5 md:flex-row ${borderStyles}`}>
			<div className = "text-center md:text-left">
				<p className="hidden text-sm text-slate-500 md:block md:text-base">{title}</p>
				<p className="text-sm text-slate-500 md:hidden">{mobileTitle}</p>
				<p className="text-lg font-[700] text-slate-600 md:text-2xl">{stat}</p>
			</div>
			<Icon className="hidden h-8 w-8 text-primary ml-auto md:block" aria-hidden="true" />
		</div>
	);
};

export default StatCard;
