import { useState, useMemo, useRef } from "react";
import Graph from "./Graph";
import StatBar from "./StatBar/StatBar";
import PrincipleInterestSwitchButton from "./PrincipleInterestSwitchButton";
import { runSimulation } from "./loanCode";
import { ViewGridAddIcon } from "@heroicons/react/outline";
import Modal from "../UI/Modal";
import LoanForm from "../UI/LoanForm";

import demoGif from '../../images/demo-gif.gif';
import welcomeImage from '../../images/loan-chill-welcome.png';

const Main = ({ loans, setLoans, paymentStrategy, monthlyPayment, totalMinimumPayment, handleShowModal, setShowModal, showModal }) => {
	const [dataToShow, setDataToShow] = useState("principal");
	
	const windowSize = useRef([window.innerWidth, window.innerHeight]);
	
	const data = useMemo(
		() => runSimulation(loans, paymentStrategy, monthlyPayment),
		[loans, paymentStrategy, monthlyPayment]
	);
	
	function ShowGraph() {
	  return (
		<main className = "col-span-12 order-1 lg:order-2 lg:col-span-8">
			<StatBar totalInterestPaid={data.totalInterestPaid} loanChartLength={data.loanChart.length} numberOfLoans = {loans.length} />
			<Graph data={dataToShow === "principal" ? data.loanChart : data.interestChart} />
			{windowSize.current[0] >= 1024 ? <PrincipleInterestSwitchButton setDataToShow = {setDataToShow} dataToShow = {dataToShow}/> : ''}
		</main>
	  );
	}
	function ShowWelcome() {
	  return (
		<main className = "col-span-12">
			<div className = "bg-cyan-800 text-white text-lg p-4 pb-0 flex rounded-lg relative gap-8 md:p-8">
				<div className = "sm:w-7/12 md:w-3/5">
					<p className = "font-extrabold leading-7 text-2xl md:text-4xl">Find the fastest and cheapest way to pay off your debt.</p>
					<p className = "leading-5 mt-4 text-md md:text-xl lg:leading-8 lg:text-2xl">LoanChill is a loan calculator that helps you explore different strategies to pay off your loans.</p>
					<img className = "sm:hidden mt-4" src = {demoGif} alt = "Image of loan graph" aria-hidden = "true"/>
					<p className = "leading-5 mt-4 text-md md:text-xl md:leading-8 lg:text-2xl">Discover an optimized payment plan to minimize interest and get debt free as fast as possible.</p>
					<p className = "leading-5 mt-4 text-md md:text-xl md:leading-8 lg:text-2xl">LoanChill is free and no login is required.</p>
					<button
						className="btn btn-accent modal-button text-amber-900 ml-auto mt-6 md:mt-8 md:btn-lg sm:mb-[170px]"
						onClick={() => handleShowModal("addFirstLoan")}
						id = "welcome-add-loan-button"
					>
						Add loan +
					</button>
					<img src = {welcomeImage} className = "mt-6 sm:absolute sm:bottom-0 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:w-[450px] sm:mt-0 lg:left-[100px] lg:translate-x-0" alt = "Image of penguin waving" aria-hidden='true'/>
				</div>
				<div className = "w-5/12 md:w-2/5 hidden sm:block">
					<img src = {demoGif} alt = "Image of loan graph" aria-hidden = "true"/>
				</div>
			</div>
		</main>
	  );
	}
	return (
		<>
			{loans.length > 0 ? <ShowGraph /> : <ShowWelcome/>}
			<Modal
				showModal={showModal === "addFirstLoan"}
				setShowModal={setShowModal}
				headline={"Add A Loan"}
				submitTitle={"Add Loan"}
				leftIcon={<ViewGridAddIcon className="h-6 w-6 text-white" aria-hidden="true" />}
			>
				<LoanForm setLoans={setLoans} handleShowModal={handleShowModal} />
			</Modal>
		</>
	);
};

export default Main;
