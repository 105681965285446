import { Outlet } from 'react-router-dom';
import Navbar from "../components/PageSections/Navbar";
import Footer from "../components/PageSections/Footer";
import { Toaster } from "react-hot-toast";

const RootLayout = () => {
	return (
		<>
			<div className="min-h-screen font-sans flex flex-col">
				<Toaster
					position="top-center"
					toastOptions={{
						duration: 4000,
						success: {
							style: {
								padding: "12px 20px",
								backgroundColor: "#b1f0b1",
								borderBottom: "3px solid #72d07e",
								fontSize: "11pt",
								fontWeight: "bold",
							},
						},
						error: {
						  style: {
							padding: "12px 20px",
							backgroundColor: "#f0b1b1",
							borderBottom: "3px solid #d07272",
							fontSize: "11pt",
							fontWeight: "bold",
						  },
						},
					}}
				/>
				<Navbar />
				<Outlet />
				<Footer />
			</div>
		</>
	);
};

export default RootLayout;